<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='24' :md='12' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='商户数' :total='total'>
          <a-tooltip title='商户数' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='authCode'>
        <a-input v-model='form.authCode' style="width: 350px" placeholder="授权编号" />
      </a-form-model-item>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 350px" placeholder="负责人/手机号/地址" />
      </a-form-model-item>
      <a-form-model-item prop='totalSall'>
        <a-select show-search
                  v-model="form.merchantBankAccountId" placeholder="总销"
                  :default-active-first-option='false'
                  :filter-option='false'
                  :allowClear='true'
                  style="width: 250px">
          <a-select-option v-for='item in accountList' :key='item.id'>
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
            defaultValue: [moment('00:00', 'HH:mm:ss'), moment('23:59', 'HH:mm:ss')],
          }"
          @change="dateChange"
        />
      </a-form-model-item>

      <a-form-model-item prop='infoStatus'>
        <a-select v-model="form.infoStatus" placeholder="资料" style="width: 250px">
          <a-select-option :value="undefined">
            全部
          </a-select-option>
          <a-select-option :value="1">
            已齐
          </a-select-option>
          <a-select-option :value="2">
            待补
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='merchantStatus'>
        <a-select v-model="form.merchantStatus" placeholder="状态" style="width: 250px">
          <a-select-option :value="1">
            待上传
          </a-select-option>
          <a-select-option :value="2">
            待签订
          </a-select-option>
          <a-select-option :value="3">
            生效中
          </a-select-option>
          <a-select-option :value="4">
            即将到期
          </a-select-option>
          <a-select-option :value="5">
            已到期
          </a-select-option>
          <a-select-option :value="6">
            暂停中
          </a-select-option>
          <a-select-option :value="7">
            已解除
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-popconfirm v-if="selectedRowKeys.length > 0" title='确定删除选中的商户吗？' ok-text='确认' cancel-text='取消' @confirm='handleDeleteAll'>
          <a-button type='primary' icon='delete'>删除</a-button>
        </a-popconfirm>        
        <a-button type='primary' icon='swap' @click='initialDeliver2'>账号更换</a-button>

        <a-button v-if="selectedRowKeys.length > 0"  type='primary' icon='swap' @click=' initialDeliver4'>批量更换总销</a-button>

      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData' :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <span slot='mobile' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>{{ text }}</a>
          </template>
        </span>
        <span slot='depositoryPermission' slot-scope='text'>
          <template>
            <a-badge dot v-if='text=="1"' :status='"success"' :text='"开启"'></a-badge>
            <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
          </template>
        </span>
        <span slot='applyActivityPermission' slot-scope='text'>
          <template>
            <a-badge dot v-if='text=="1"' :status='"success"' :text='"开启"'></a-badge>
            <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
          </template>
        </span>
        <span slot='activityPermission' slot-scope='text'>
          <template>
            <a-badge dot v-if='text=="1"' :status='"success"' :text='"开启"'></a-badge>
            <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
          </template>
        </span>
        <span slot='merchantStart' slot-scope='text, record'>
          <template v-if="text">
            {{text}} 至 {{record.merchantEnd}}
          </template>
        </span>
        <span slot='merchantBankAccountName' slot-scope='text, record'>
              {{text}}
        </span>
        <span slot='merchantStatus' slot-scope='text, record'>
          <template>
            <a-badge dot v-if='record.pause==1' :status='"error"' :text='"暂停中"'></a-badge>
            <a-badge dot v-else-if='text=="1"' :status='"processing"' :text='"待上传"'></a-badge>
            <a-badge dot v-else-if='text=="2"' :status='"warning"' :text='"待签订"'></a-badge>
            <a-badge dot v-else-if='text=="3"' :status='"success"' :text='"生效中"'></a-badge>
            <a-badge dot v-else-if='text=="4"' :status='"warning"' :text='"即将到期"'></a-badge>
            <a-badge dot v-else-if='text=="6"' :status='"warning"' :text='"已暂停"'></a-badge>
            <a-badge dot v-else-if='text=="7"' :status='"error"' :text='"已解除"'></a-badge>
            <a-badge dot v-else-if='text=="5"' :status='"error"' :text='"已到期"'></a-badge>
            <a-badge dot v-else :status='"default"' :text='"其他"'></a-badge>
          </template>
        </span>
        <span slot="businessLicenseImg" slot-scope='text, record'>
          <template>
            {{isBu(record)}}
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='initialDeliver1(record)'>删除</a>
              <a-popconfirm v-if="record.pause == 1" title='确定恢复吗？' ok-text='确认' cancel-text='取消' @confirm='handleStop(record)'>
                <a href='#'>恢复</a>
              </a-popconfirm>
              <a-popconfirm v-else-if="record.merchantStatus == 3||record.merchantStatus == 4" title='确定暂停吗？' ok-text='确认' cancel-text='取消' @confirm='handleStop(record)'>
                <a href='#'>暂停</a>
              </a-popconfirm>
              <a-popconfirm v-if="record.merchantStatus !=7" title='确定解除吗？' ok-text='确认' cancel-text='取消' @confirm='handleJieChu(record)'>
                <a href='#'>解除</a>
              </a-popconfirm>
               <a @click='initialDeliver3(record)'>总销</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
    <a-modal title='删除商户' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='商户名称' prop='merchantName'>
          {{deliver1.form.merchantName}}
        </a-form-model-item>
        <a-form-model-item label='负责人' prop='masterName'>
          {{deliver1.form.masterName}}
        </a-form-model-item>
        <a-form-model-item label='授权编号' prop='authCode'>
          {{deliver1.form.authCode}}
        </a-form-model-item>
        <a-form-model-item label='审批时间' prop='merchantAuditTime'>
          {{deliver1.form.merchantAuditTime}}
        </a-form-model-item>

        <a-form-model-item label='现金折扣' prop='merchantAuditTime'>
          {{deliver1.form.cashBalance}}
        </a-form-model-item>
        <a-form-model-item label='商品折扣' prop='merchantAuditTime'>
          {{deliver1.form.discountDes}}
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='账号更换' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
            ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver2.loading' :width="800">
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='原账号' prop='oldMobile'>
          <a-input v-model='deliver2.form.oldMobile' />
        </a-form-model-item>
        <a-form-model-item label='新账号' prop='newMobile'>
          <a-input v-model='deliver2.form.newMobile' />
        </a-form-model-item>
        <a-form-model-item label='更换原因' prop='reason'>
          <a-input v-model='deliver2.form.reason' />
        </a-form-model-item>
        <a-form-model-item label='备注'>  
          账号更换后，归属、订单、名额、业绩不变
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='总销更换' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver3' :confirmLoading='deliver3.loading' :width="600">
      <a-form-model ref='form3' :model='deliver3.form' :rules='deliver3.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='总销' prop='merchantBankAccountId'>
          <a-select show-search
                    v-model="deliver3.form.merchantBankAccountId" placeholder="总销"
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    style="width: 250px">
            <a-select-option v-for='item in accountList' :key='item.id'>
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='批量' prop='isPiLiang' style="display: none;">
          <a-input  v-model="deliver3.form.isPiLiang"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  queryMerchant,
  getMerchantDashboard,
  deleteMerchant,
  putMemberTransfer,
  putMerchant,
  handelJieChu,
  getMember
} from '@/api/member'
import { queryAccount} from '@/api/account'
import moment from 'moment';

const columns = [
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 150,
    title: '商户简称',
    dataIndex: 'merchantShortName',
  },
  {
    width: 150,
    title: '地区',
    dataIndex: 'location',
  },
  {
    width: 150,
    title: '负责人',
    dataIndex: 'masterName',
  },
  {
    width: 150,
    title: '授权编号',
    dataIndex: 'authCode',
  },
  // {
  //   width: 150,
  //   title: '仓库权限',
  //   dataIndex: 'depositoryPermission',
  //   scopedSlots: { customRender: 'depositoryPermission' }
  // },
  // {
  //   width: 150,
  //   title: '订单跟踪权限',
  //   dataIndex: 'followPermission',
  //   scopedSlots: { customRender: 'followPermission' }
  // },
  // {
  //   width: 150,
  //   title: '活动权限',
  //   dataIndex: 'activityPermission',
  //   scopedSlots: { customRender: 'activityPermission' }
  // },
  // {
  //   width: 100,
  //   title: '门票数',
  //   dataIndex: 'ticketBalance',
  // },
  // {
  //   width: 100,
  //   title: '活跃度',
  //   dataIndex: 'activeLevel',
  // },
  {
    width: 100,
    title: '总销',
    dataIndex: 'merchantBankAccountName',
    scopedSlots: { customRender: 'merchantBankAccountName' }
  },
  {
    width: 150,
    title: '活动申请',
    dataIndex: 'applyActivityPermission',
    scopedSlots: { customRender: 'applyActivityPermission' }
  },
  {
    width: 150,
    title: '资料',
    dataIndex: 'businessLicenseImg',
    scopedSlots: { customRender: 'businessLicenseImg' }
  },
  // {
  //   width: 200,
  //   title: '审批时间',
  //   dataIndex: 'merchantAuditTime',
  // },
  {
    width: 200,
    title: '代理期限',
    dataIndex: 'merchantStart',
    scopedSlots: { customRender: 'merchantStart' }
  },,
  {
    width: 150,
    title: '状态',
    dataIndex: 'merchantStatus',
    scopedSlots: { customRender: 'merchantStatus' }
  },
  // {
  //   width: 100,
  //   title: 'type',
  //   dataIndex: 'type',
  //   scopedSlots: { customRender: 'type' }
  // },
  {
    width: 200,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'MemberList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      selectedRowKeys:[],
      total:0,
      // 筛选表单
      dateArr: [],
      formatType: 'YYYY-MM-DD HH:mm',
      form: {
        keyword: ""
      },
      form3: {
        keyword: ""
      },
      //统计数据
      loading: true,
      dashboard: {},
      // 表头
      columns,
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        this.getDashboard()
        return queryMerchant(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            // debugger;
            this.total=datum.total;
            return datum;
          })
      },
      accountList: [],
      deliver1: { visible: false, form: {},rules: [] },
      deliver2: { visible: false,loading: false, form: {},
        rules: {
          oldMobile: [{ required: true, message: '请填写原手机', trigger: 'change' }],
          newMobile: [{ required: true, message: '请填写新手机', trigger: 'change' }],
          reason: [{ required: true, message: '请填写更换原因', trigger: 'change' }],
        }, 
      },
      deliver3: { visible: false,loading: false, form: {}, rules:
          {
            merchantBankAccountId: [{ required: true, message: '请选择总销', trigger: 'change' }],
          }
         },
    }
  },
  created() {
      this.initAccount();
  },
  methods: {
    isBu(record){
      if(record.businessLicenseImg && record.foodLicenseImg){
        return "已齐";
      }else{
        return "待补";
      }

    },
    getDashboard() {
      let param = Object.assign(this.queryParam, this.form);
      getMerchantDashboard(param).then(result => {
        this.dashboard = result;
        this.loading = false;
      })
    },
    initAccount(){
      queryAccount()
        .then(datum => {
          this.accountList = datum;
        })
    },
    moment,
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleDeleteAll() {
      const requestList = [];
      this.selectedRowKeys.forEach(item => {     
         requestList.push(deleteMerchant({ id: item }))
      });
      if (requestList.length == 0) {
        this.$message.warning('至少选择一个订单');
        return;
      }
      Promise.all(requestList).then(result => {
        this.$message.success('操作成功');
        this.getDashboard()
        this.$refs.table.refresh(true)
      })
    },
    initialDeliver2() {
        this.deliver2.form = {};
        this.deliver2.visible = true
    },
    handleDeliver2() {
      this.$refs.form2.validate((result) => {
        if (result) {
          this.deliver2.loading = true;
          putMemberTransfer(this.deliver2.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    handleDeliver3() {
      this.$refs.form3.validate((result) => {
        if (result) {
          this.deliver3.loading = true;
          let _form = this.deliver3.form;
          let isPiLiang = _form.isPiLiang;
          var index = 0;
          if(isPiLiang==='true'){
            //批量更换
            let allId =  this.selectedRowKeys;
            for(let i=0;i<allId.length;i++){
              //循环更改总销
              this.deliver3.form.id = allId[i];
              // let _ff =  this.deliver3.form;
              // console.log(_ff)
              putMerchant(Object.assign({id:allId[i],merchantBankAccountId:this.deliver3.form.merchantBankAccountId})).then(_result => {
                // debugger;
                index = index +1;
                this.$message.success('操作成功,总共处理：'+(allId.length)+"个，已完成："+(index)+"个");
              }).catch((err) => {
                this.$message.success('更换失败,总共处理：'+(allId.length)+"个，已完成："+(index)+"个");
                this.$refs.table.refresh(true)
                this.deliver3.visible = false
                this.deliver3.loading = false;
              }).finally(()=>{
                if(allId.length===index){
                  this.$refs.table.refresh(true)
                  this.deliver3.visible = false
                  this.deliver3.loading = false;
                }
              });
            }
          }else{
            putMerchant(Object.assign(this.deliver3.form)).then(_result => {
              this.$message.success('操作成功')
              this.$refs.table.refresh(true)
              this.deliver3.visible = false
              this.deliver3.loading = false;
            });
          }
        }
      })
    },
    initialDeliver3(record) {
      // this.deliver3.form = Object.assign(this.deliver1.form, record)
      this.deliver3.visible = true
      this.deliver3.form = {merchantBankAccountId:record.merchantBankAccountId,id:record.id,isPiLiang:'false'};
    },
    initialDeliver4() {
      // this.deliver3.form = Object.assign(this.deliver1.form, record)
      let allId =  this.selectedRowKeys;
      if(allId.length>0){
        // debugger;
        this.deliver3.visible = true
        this.deliver3.form = {isPiLiang:'true'};
      }else{
        this.$message.error("请选择需要更换总销的数据！");
      }

    },
    initialDeliver1(record) {

      getMember({id: record.id}).then(rel => {
        let cab = rel.cashBalance;
        this.deliver1.form = Object.assign(this.deliver1.form, record)
        this.deliver1.form.cashBalance = cab;
        this.deliver1.visible = true
      })

    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          deleteMerchant(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.getDashboard()
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
          })
        }
      })
    },
    handleStop(record) {
      putMerchant({ id: record.id, pause: record.pause == 1 ? 0 : 1 }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
      this.dateArr = []
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleEdit(record) {
      this.$router.push({ path: '/member/edit', query: { id: record.id } })
    },
    handleJieChu(record){
      handelJieChu({ id: record.id}).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      });
    },
  }
}
</script>
